.App {
  text-align: center;
}

.divider {
  background-color: black !important;
}

.typography {
  color: #888;
}